import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { UrlHelper } from "../../../skupno/src/ts/utils/url-helper";
import { TableWidget, TableWidgetTableMap } from "../../../skupno/src/ts/widgets/table-widget";
import { NotificationApiClient } from "../ts/clients/notifications-api-client";
import { TranslationService } from "../ts/translation-service";
import { ModalConfirm } from "./modal-confirm";
import template from "./obvestila-objavljena-obvestila-view.html";
import ObvestiloPodrobnoModal from "./obvestila-podrobno-modal.html";

@injectable()
export class ObvestilaObjavljenaObvestilaView {
    private _apiClient: NotificationApiClient;
    private _translationService: TranslationService;
    private _tableWidget: TableWidget = null!;
    //private notifications: Array<any>
    private itemsPerPage = 24;
    private _searchForm: JQuery<HTMLElement> | null = null;
    private _searchInputElement: JQuery<HTMLElement> | null = null;
    private _notificationTypeDropdownElement: JQuery<HTMLElement> | null = null;

    // private _delay = (function () {
    //     var timer = 0;    
    //     return function (callback: Function, ms: number) {
    //         window.clearTimeout(timer);
    //         timer = window.setTimeout(callback, ms);
    //     };
    // })();

    public constructor(notificationApiClient: NotificationApiClient, translationService: TranslationService, private notyf:Notyf) {
        this._apiClient = notificationApiClient;
        this._translationService = translationService;
        //this.notifications = new Array<any>;
    }

    private async search(): Promise<void> {
        await this._tableWidget.refresh(1, this._translationService.currentTranslations);
    }

    private async onFilterFormSubmit(ev: JQuery.SubmitEvent) {
        ev.preventDefault();
        await this.search();
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {
            var initModel = await this._apiClient.addFormInitialization();

            this._renderData(initModel);
            this._searchForm = $('#main').find("form[name=notification-search-form]");
            this._searchInputElement = this._searchForm.find("[name=search]");
            this._notificationTypeDropdownElement = this._searchForm.find("[name=notificationType-id]");

    

            this._searchForm.on("submit",  (e) =>  {
                this.onFilterFormSubmit(e)
            });

            var rootElement = $("#main").find(".objavljena-obvestila-table-placeholder");
            var tableWidget = new TableWidget({
                tableMap: this.dataTableModel(),
                rootElement: rootElement,
                getData: async (currentPage) => {
                    if (currentPage == 1) {
                        var page = UrlHelper.getPageFromUrl();
                        if (page != 1) {
                            currentPage = page;
                        }
                    }
                    var data = await this._apiClient.adminList(currentPage, this.itemsPerPage, this._searchInputElement?.val() as string, this._notificationTypeDropdownElement?.val() as string);
                    //this.notifications = data.items; 
                    return data;
                },
                onRender: () => {
                    var translations = this._translationService.currentTranslations;
                    rootElement.find(".popover-edit-notifications").each((_index, element) => {                        
                        var id = element.getAttribute("data-id");
                        $(element).popover({
                            container: rootElement[0],
                            content: '<ul><li><a tabindex="0" href="#/obvestila/uredi/' + id + '" class="edit">' + translations.Edit + '</a></li><li><a tabindex="0" href="#" class="delete" data-action="delete" data-id="' + id + '">' + translations.Delete + '</a></li></ul>',
                            html: true,
                            sanitize: false,
                            trigger: "focus",
                            delay: {
                                show: 0,
                                hide: 200
                            }
                        });
                    });
                    this.initSubscribtionModalButton();
                }
            });
            tableWidget.refresh(1, this._translationService.currentTranslations);
            this._tableWidget = tableWidget;

            var translations = this._translationService.currentTranslations;
            rootElement.on("click", "[data-action=delete]", async (ev) => {
                ev.preventDefault();
                var id = parseInt($(ev.currentTarget).attr("data-id") as string, 10);
                ModalConfirm.confirm({
                    cancelText: translations.Cancel,
                    confirmText: translations.Confirm,
                    content: translations.ConfirmDeleteContent,
                    confirmType: 'brisanje'
                }).then(() => {
                    this._apiClient.delete(id).then(() => {
                        tableWidget.refresh(tableWidget.currentPage, this._translationService.currentTranslations);
                    })
                        .catch(() => {
                            this.notyf.error(translations.DeleteError);
                        });
                }).catch(() => {
                });
            });
            rootElement.on("click", "[data-action=publish][data-id]", (ev) => {
                ev.preventDefault();
                var id = parseInt($(ev.currentTarget).attr("data-id") as string, 10);
                this._apiClient.publish(id).then(() => {
                    tableWidget.refresh(tableWidget.currentPage, this._translationService.currentTranslations);
                })
                    .catch(() => {
                        this.notyf.error(translations.PublishError);
                    });

            });

        } catch (e) {
            console.debug("error", e);
            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(initModel :any): void {                
        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            "imageUrlDarkAttachmentSmall": "../img/icon-dark-attachment-small.svg",
            "imageUrlVerticalDots": "../img/icon-vertical-dots.svg",
            data: initModel,
            translations: this._translationService.currentTranslations

        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);
    }

    private _renderDetailsModal(notification: any): void {
        const viewModel = {
            data: notification,
            hasAttachments: notification.attachments && notification.attachments.length > 0,
            translations: this._translationService.currentTranslations,
            formatDateTime: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format("L LT");
                };
            }
        } as any;
        const htmlTemplate = ObvestiloPodrobnoModal;
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#notifications-admin-details-modal').html(html);

        $('#main').find("[data-action=download]").on("click", async (ev) => {
            ev.preventDefault();
            var priponkaId = parseInt($(ev.currentTarget).attr("data-id") as string, 10);
            var fileContent = await this._apiClient.attachmentDownload(priponkaId);


            var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
            var url = URL.createObjectURL(blob);

            var fileName: string = fileContent.fileName;

            if (!fileName) {
                fileName = $(ev.currentTarget).attr("download") as string;
                fileName = fileName.toLowerCase().replace(/\s+/g, '_');
                fileName = fileName.replace(/(_)_+|(\\.)\\.+|(-)-+/, "$1$2$3");
            }

            var fileLink = document.createElement('a');
            fileLink.href = url;
            fileLink.download = fileName;
            fileLink.click();

        });
    }

    public initSubscribtionModalButton() {
        var self = this;
        $("#main").find(".notification-details-btn").on("click", async function (ev) {
            const target = ev.currentTarget as HTMLInputElement;
            var dataId = target.getAttribute("data-id");
            if (!dataId) {
                return;
            }
            var id = parseInt(dataId);

            await self._apiClient.notification(id)
                .then((list) => {
                    self._renderDetailsModal(list);
                    $("#notifications-admin-details-modal").modal("show");
                })
                .catch((ex) => {
                    if (ex.statusCode == 400) {
                        $('#main').text(self._translationService.currentTranslations[ex.message]);
                    }
                    else {
                        $('#main').text(self._translationService.currentTranslations["LoadingError"]);
                    }
                });

            //var notification = self.notifications.find((el) => el.id == id);
            //if (!notification) {
            //    return;
            //}
            //self._renderDetailsModal(notification);
            //$("#notifications-admin-details-modal").modal("show");
        });
    }

    private dataTableModel(): TableWidgetTableMap {
        var translations = this._translationService.currentTranslations;
        const tableMap: TableWidgetTableMap = {
            title: translations.AllPublishedNotices,
            controls: '<a href="#/obvestila/dodaj-novo" class="add">' + translations.AddNotification +'</a>',
            classInsert: "table-wrapper",
            noDataContent: translations.NoData,
            columns: [
                {
                    name: translations.subject1,
                    html: '<a class="notification-details-btn" data-id="{{id}}" href="javascript:void(0)">{{subject}}</a>',
                    class: "w-50",
                },
                {
                    name: translations.Author,
                    value: "author"
                },
                {
                    name: translations.Day,
                    html: "{{#formators.dateFormat}}{{dateCreated}}{{/formators.dateFormat}}"
                },
                {
                    name: translations.Statistics,
                    html: `<a data-bs-toggle="collapse" href="#statistic-collapse-{{id}}" role="button" aria-expanded="false" aria-controls="collapseExample">{{readCount}} / {{envelopeCount}}</a>
                                            <div class="table-stat collapse" id="statistic-collapse-{{id}}">
                                                <table class="table table-borderless">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">`+ translations.Unknown +`</th>
                                                            <td>{{statusUnknownCount}}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Brez E-pošte`+ translations.StatusNoEmail +`</th>
                                                            <td>{{statusNoEmailCount}}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Nepotrjeni`+ translations.StatusUnverified +`</th>
                                                            <td>{{statusUnverfiedCount}}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">`+ translations.Sent +`</th>
                                                            <td>{{statusSentCount}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>`
                    
                },
                {
                    name: '<img src="../img/icon-dark-attachment-small.svg" class="img-fluid">',
                    value: "attachmentCount"
                },
                {
                    name: "&nbsp;",
                    html: `{{#allowPublish}}<a href="#" data-action="publish" data-id="{{id}}" class="btn btn-sm btn-outline-primary w-100">` + translations.Publish + `</a>{{/allowPublish}}`
                },
                {
                    class: "text-center",
                    name: "&nbsp;",
                    html: `{{#allowDelete}}<div class="edit">
                                <a tabindex="0" class="popover-edit-notifications" data-id="{{id}}" role="button" data-bs-toggle="popover" data-bs-trigger="focus" data-bs-placement="bottom" title="" data-bs-content="">
                                    <img src="../img/icon-vertical-dots.svg" class="img-fluid" />
                                </a>
                            </div>{{/allowDelete}}`
                }
            ]
        };

        return tableMap;
    }
}