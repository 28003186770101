import { parse } from "content-disposition-header";
import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { DownloadedFile } from "../models/downloaded-file";
import { ListResponse } from "../models/list-response";
import { Notification } from "../models/notification"
import { NotificationAddInitializationModel, NotificationEditInitializationModel } from "../models/notification-add-initialization-model";
import { NotificationCalculateUsersRequest } from "../models/notification-calculate-users-request";
import { NotificationSentResponse } from "../models/notification-sent-response";
import { User } from "../models/user";

@injectable()
export class NotificationApiClient extends ApiClientBase {
    public name = "NotificationApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator")  authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getList(page?: number, limit?: number): Promise<ListResponse<Notification>> {
        const data: JSON = <JSON><unknown>{
            "page": page,
            "limit": limit
        };
        return await this._callApi('/notification/inbox', 'GET', data) as ListResponse<Notification>;
    }

    public async adminList(page?: number, limit?: number, subject?: string, notificationTypeId?: string ): Promise<ListResponse<NotificationSentResponse>> {
        const data: JSON = <JSON><unknown>{
            "page": page,
            "limit": limit,
            "subject": subject,
            "notificiationTypeId": notificationTypeId
        };
        return await this._callApi('/notification/admin/list', 'GET', data) as ListResponse<NotificationSentResponse>;
    }

    public async addFormInitialization(): Promise<NotificationAddInitializationModel> {
        return await this._callApi('/notification/add/init', 'GET', null) as NotificationAddInitializationModel;
    }

    public async editFormInitialization(id: number): Promise<NotificationEditInitializationModel> {
        return await this._callApi('/notification/edit/init/' + id, 'GET', null) as NotificationEditInitializationModel;
    }


    public async recipientCount(request: NotificationCalculateUsersRequest): Promise<number> {
        return await this._callApi('/notification/recipient/count', 'POST', JSON.stringify(request)) as number;
    }

    public async userSearch(searchTerm: string): Promise<Array<User>> {
        return await this._callApi('/notification/user/search', 'GET', searchTerm) as Array<User>;
    }

    public async userSearchOptions(prepareRequest: (request: JQueryAjaxSettings) => void): Promise<JQuery.AjaxSettings> {
        return await this._createAjaxOptionsWithToken('/notification/user/search', 'GET', "", false, "json", undefined, undefined, prepareRequest);
    }

    public async create(request: FormData): Promise<void> {
        return await this._callApi('/notification/add/create', 'POST', request, true, "", null, null, (settings) => {
            settings.contentType = false;
            settings.processData = false;
        });
    }

    public async edit(request: FormData): Promise<void> {
        return await this._callApi('/notification/edit', 'POST', request, true, "", null, null, (settings) => {
            settings.contentType = false;
            settings.processData = false;
        });
    }

    public async uploadXlsFile(request: FormData): Promise<any> {
        return await this._callApi('/notification/uploadXlsFile', 'POST', request, true, "", null, null, (settings) => {
            settings.contentType = false;
            settings.processData = false;
        }) as any;
    }


    public async delete(id: number): Promise<void> {
        return await this._callApi('/notification/delete/' + id, 'POST', null, true, "");
    }

    public async publish(id: number): Promise<void> {
        return await this._callApi('/notification/publish/' + id, 'POST', null, true, "");
    }

    public async envelope(id: number): Promise<Notification> {
        return await this._callApi('/notification/envelope/'+id, 'GET') as Notification;
    }

    public async notification(id: number): Promise<Notification> {
        return await this._callApi('/notification/notification/' + id, 'GET') as Notification;
    }

    public async read(id: number): Promise<Notification> {
        return await this._callApi('/notification/read/' + id, 'GET') as Notification;
    }

    public async attachmentDownload(priponkaId: number): Promise<DownloadedFile> {
        const data = {};
        var file: DownloadedFile = null as any;

        await this._callApi('/attachments/download/' + priponkaId, 'GET', data, true, "binary", (data, _status, jqueryXhr: JQueryXHR) => {
            var contentDisposition = jqueryXhr.getResponseHeader("Content-Disposition");
            var filename: string = "";
            if (contentDisposition) {
                var contentDispositionParsed = parse(contentDisposition as string);
                filename = contentDispositionParsed.parameters.filename;
            }
            var filetype = jqueryXhr.getResponseHeader("Content-Type");
            file = {
                fileName: filename,
                fileType: filetype,
                fileContent: data
            } as DownloadedFile;
        });

        return file;
    }
}